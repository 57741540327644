export default Object.freeze({
  CAMERA: "camera",
  COLOR: "color",
  DESCRIPTION: "description",
  IMAGES: "images",
  MARK: "mark",
  STATE: "state",
  MODEL: "model",
  NAME: "name",
  NUMBER_OF_VIEWS: "numberOfViews",
  PRICE: "price",
  PRICE_SALES: "sales",
  PROCESSOR: "processor",
  SCREEN_SIZE: "screenSize",
  STORAGE_CAPACITY: "storageCapacity",
  UNIQUE_ID: "uniqueId",
  LEVEL: "level",
  ORDER: "order",
  RELEASE_DATE: "releaseDate",
  PARENT_ID: "parentId",
  EXTERNAL_ID: "externalId",
  MARK_ID: "markId",
  EMAIL: "email",
  USER_ID: "userId",
  USER_NAME: "userName",
  PRODUCT_ID: "productId",
  CATEGORY: "category",
  SUBCATEGORY: "subCategory",

  PRODUCT_REFERENCE_ID: "productReferenceId",
  CREATED_BY: "createdBy",
  CLASS_NAME: "className",
  SUBSCRIBERS: "subscribers",
  SUBSCRIBER_ID: "subscriberId",
  FOLLOWER_ID: "followerId",
  USER_ID_RATING: "userIdRating",
  USER_ID_WHO_RATED: "userIdWhoRated",
  DELETED: "deleted",
  DELETED_DATE: "deletedDate",
  CREATION_DATE: "creationDate",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  PHONE_NUMBER: "phoneNumber",
  PHONE_COUNTRY_NAME_CODE: "phoneCountryNameCode",
  GENDER: "gender",
  ADDRESS: "address",
  ADDRESSES: "addresses",
  STREET_NUMBER: "streetNumber",
  LOCALITY: "locality",
  ADMINISTRATIVE_AREA: "administrativeArea",
  POSTAL_CODE: "postalCode",
  ROUTE: "route",
  ADDRESS_NAME: "addressName",
  PROFESSIONAL: "professional",
  PRO_USER_ACCOUNT_ID: "professionalUserAccountId",
  PRIVATE_USER_ACCOUNT_ID: "privateUserAccountId",
  RECEIVER_ID: "receiverId",
  IS_READ: "read",
  NOTIFICATION_NAME: "notificationName",
  OBJECT: "object",
  MY_CONVERSATION: "myConversation",
  PHOTO_URL: "photoUrl",
  SENDER_ID: "senderId",
  UPDATED_DATE: "updatedDate",
  UPDATED_BY: "updatedBy",
  HISTORY_SEARCH: "searchHistoryList",
  SALES: "sales",
  DELETED_BY: "deletedBy",
  SELECTED: "selected",
  ROLE: "role",
  ADMIN: "admin",
  EDITOR: "editor",
  GUEST: "guest",
  LIBELLE: "libelle",
  PUBLISH: "publish",
  ISODATE: "isoDate",
  SOURCE_NAME: "sourceName",
  STATUS: "status",
  ORDER_FAQ: "orderFaq",
  ORDER_IDENTIFIER: "orderIdentifier",
  NUMBER_PRODUCTS_INVALIDATED: "number_products_invalidated",
  NUMBER_PRODUCTS_VALIDATED: "number_products_validated",
  NUMBER_PRODUCTS_PUBLISHED: "number_products_published",
  NUMBER_PRODUCTS_POSTED: "number_products_posted",
  NUMBER_TOTAL_PRODUCTS: "number_total_products",
  NUMBER_TOTAL_ORDERS: "number_total_orders",
  NUMBER_ORDERS_CREATED: "number_orders_created",
  NUMBER_ORDERS_IN_PREPARATION: "number_orders_in_preparation",
  NUMBER_ORDERS_AWAITING_DELIVERY: "number_orders_awaiting_delivery",
  NUMBER_ORDERS_OUT_FOR_DELIVERY: "number_orders_out_for_delivery",
  NUMBER_ORDERS_RETURNED: "number_orders_returned",
  NUMBER_ORDERS_LIVERY: "number_orders_livery",
  NUMBER_ORDERS_CANCELED: "number_orders_canceled",
});
